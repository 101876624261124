import React from "react";

const Timer = ({ hours, minutes, seconds }) => {
  const formatNumber = (value) => {
    return Number(value).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };
  return (
    <div style={{ fontSize: "18px", color: "white" }}>
      {formatNumber(hours)}:{formatNumber(minutes)}:{formatNumber(seconds)}
    </div>
  );
};

export default Timer;
