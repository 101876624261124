import { Modal } from "antd";
import React from "react";

const PreviewModal = ({ visible, onCancel, previewImageUrl, ...props }) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      className="generalModal"
      bodyStyle={{
        padding: "16px",
      }}
      width={440}
      onCancel={onCancel}
      centered
      closable={false}
    >
      <div>
        <img
          src={previewImageUrl}
          alt="image"
          className="width-100 height-100"
          style={{ display: "block" }}
        />
      </div>
    </Modal>
  );
};

export default PreviewModal;
