import React, { useEffect, useState } from "react";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { checkIsImage, getFileIcon } from "./utils";
import { FileCardWrapper } from "./index.style";
import PreviewModal from "./PreviewModal";

const FileCard = ({
  showPreviewIcon,
  showRemoveIcon,
  customFileObj,
  handleRemoveFile,
}) => {
  const [isImage, setIsImage] = useState(false);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const handleClosePreviewModal = () => {
    setPreviewImageUrl(null);
    setIsOpenPreviewModal(false);
  };

  const handlePreviewFile = () => {
    if (isImage) {
      setPreviewImageUrl(customFileObj.url || customFileObj.blobUrl);
      setIsOpenPreviewModal(true);
    } else {
      window.open(customFileObj.url || customFileObj.blobUrl);
    }
  };

  useEffect(() => {
    if (customFileObj.url) {
      if (checkIsImage(customFileObj.url)) {
        setIsImage(true);
      } else {
        setIsImage(false);
      }
    } else {
      if (
        customFileObj.originalFile &&
        checkIsImage(customFileObj.originalFile.name)
      ) {
        setIsImage(true);
      } else {
        setIsImage(false);
      }
    }
  }, [customFileObj]);

  return (
    <FileCardWrapper>
      {isImage ? (
        <img
          className="width-100 height-100"
          style={{ display: "block" }}
          src={customFileObj.url || customFileObj.blobUrl}
          alt={"image"}
        />
      ) : (
        <div className="width-100 height-100 display-flex justify-center align-center">
          {getFileIcon(customFileObj.url || customFileObj.originalFile.name)}
        </div>
      )}
      {(showPreviewIcon || showRemoveIcon) && (
        <div className="overlay">
          {showPreviewIcon && (
            <FontAwesomeIcon
              icon={faEye}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={handlePreviewFile}
            />
          )}
          {showRemoveIcon && (
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={handleRemoveFile}
            />
          )}
        </div>
      )}
      {isOpenPreviewModal && (
        <PreviewModal
          visible={isOpenPreviewModal}
          onCancel={handleClosePreviewModal}
          previewImageUrl={previewImageUrl}
        />
      )}
    </FileCardWrapper>
  );
};

export default FileCard;
