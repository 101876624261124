import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'

import './delete.css'

const ConfirmModal = props => {
  const [visible, setVisible] = useState(false)

  useEffect(
    () => {
      setVisible(props.visible)
    },
    [props.visible]
  )

  const handleOk = e => {
    props.handleSuccess()
  }

  const handleCancel = e => {
    props.handleCancel()
  }

  return (
    <Modal
      title=''
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      className='delete-modal'
      footer={[
        <Button key='delete' type='primary' onClick={handleOk}>
          {props.okText}
        </Button>,
        <Button key='cancel' onClick={handleCancel}>
          {props.cancelText}
        </Button>
      ]}>
      <h2 className='text-center title'>{props.title}</h2>
      <p className='text'>{props.description}</p>
    </Modal>
  )
}

export default ConfirmModal
