import styled from "styled-components";

export const TableStyleWrapper = styled.div`
  /* ant table style */
  .ant-table-thead > tr > th {
    padding: 12px !important;
    font-size: 14px !important;
    background-color: ${(props) =>
      props.type === "primary" ? "#303a75" : "#e8e8e8"} !important;
    color: ${(props) =>
      props.type === "primary" ? "#ffffff" : "#1d2346"} !important;
    border-right: none;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 12px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    border-right: none !important;
  }
  /* .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #e8e8e8 !important;
  } */
  /* .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #e8e8e8 !important;
  } */
  .ant-pagination {
    margin: 15px 0 0 0 !important;
  }
  .ant-table-fixed {
    table-layout: fixed !important;
    border-right: 1px solid #e8e8e8 !important;
  }
  .ant-table-fixed-right {
    table {
      border-left: none !important;
    }
  }
  .ant-table-tbody {
    .addButton {
      background: #d5e0f8 !important;
      color: #486bb3 !important;
      &:hover {
        border: 1px solid #486bb3 !important;
      }
    }
    .editButton {
      background: #d5e0f8 !important;
      color: #4f75c1 !important;
      &:hover {
        border: 1px solid #4f75c1 !important;
      }
    }
    .viewButton {
      background: #def4de !important;
      color: #008000 !important;
      &:hover {
        border: 1px solid #008000 !important;
      }
    }
    .reportButton {
      background: #d5e0f8 !important;
      color: #4f75c1 !important;
      &:hover {
        border: 1px solid #4f75c1 !important;
      }
    }
    .copyButton {
      background: #edf5cf !important;
      color: #738822 !important;
      &:hover {
        border: 1px solid #738822 !important;
      }
    }
    .historyButton {
      background: #f5cff3 !important;
      color: #d348cc !important;
      &:hover {
        border: 1px solid #d348cc !important;
      }
    }
    .deleteButton {
      background: #f8e7e7 !important;
      color: #f64744 !important;
      &:hover {
        border: 1px solid #f64744 !important;
      }
    }
    .toolButton {
      background: #c8d9f7 !important;
      color: #1047de !important;
      &:hover {
        border: 1px solid #1047de !important;
      }
    }
    .saveButton {
      background: #def4de !important;
      color: #008000 !important;
      &:hover {
        border: 1px solid #008000 !important;
      }
    }
    .cancelButton {
      background: #f8e7e7 !important;
      color: #f64744 !important;
      &:hover {
        border: 1px solid #f64744 !important;
      }
    }
  }
  /* custom scrollbar for antd table */
  .ant-table-body {
    table {
      border-right: 1px solid #e8e8e8 !important;
    }
    // For Firefox
    scrollbar-width: 4px;
    scrollbar-color: rgba(1, 1, 1, 0.2) #fff;
    // For Webkit browsers (Chrome, Safari, etc.)
    &::-webkit-scrollbar {
      width: 4px !important;
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(1, 1, 1, 0.2);
      border-radius: 0 !important;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(1, 1, 1, 0.1);
      border-radius: 0 !important;
    }
    scroll-behavior: smooth !important;
    transition: scroll-behavior 0.3s linear !important;
  }
`;
