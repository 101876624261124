import React from "react";
import {
  faBalanceScale,
  faGears,
  faShieldAlt,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import StatisticsCard from "../uielements/StatisticsCard";
import { withRouter } from "react-router-dom";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const SettingsCount = (props) => {
  return (
    <GridContainer className="width-100">
      <StatisticsCard
        title={"My Settings"}
        icon={
          <FontAwesomeIcon
            icon={faUserGear}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#9D6AB0"}
        onClick={() => props.history.push("/my-settings")}
        showOnlyTitle
      />
      <StatisticsCard
        title={"Global Settings"}
        icon={
          <FontAwesomeIcon
            icon={faGears}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#8BBC27"}
        showOnlyTitle
        onClick={() => { }}
      />
      <StatisticsCard
        title={"Global Certifications"}
        icon={
          <FontAwesomeIcon
            icon={faGears}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#20CCE2"}
        showOnlyTitle
        onClick={() => props.history.push("/certification")}
      />
      <StatisticsCard
        title={"Weights and Measures"}
        icon={
          <FontAwesomeIcon
            icon={faBalanceScale}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#EE5A92"}
        showOnlyTitle
        onClick={() => props.history.push("/global-settings")}
      />
      <StatisticsCard
        title={"Business Location"}
        icon={
          <FontAwesomeIcon
            icon={faGears}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#348EE2"}
        showOnlyTitle
        onClick={() => props.history.push("/company")}
      />
      <StatisticsCard
        title={"Safety"}
        icon={
          <FontAwesomeIcon
            icon={faShieldAlt}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#f0aa32"}
        showOnlyTitle
        onClick={() => props.history.push("/safety")}
      />
    </GridContainer>
  );
};

export default withRouter(SettingsCount);
