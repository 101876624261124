import styled from "styled-components";

export const StyleWrapper = styled.div`
  .browseFileButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #4f75c1;
    padding: 12px 14px;
    font-size: 16px;
    color: #4f75c1;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
  }
  .uploadButton {
    width: 104px;
    height: 104px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    .btnText {
      line-height: 20px !important;
      text-align: center;
    }
  }
`;

export const FileCardWrapper = styled.div`
  width: 104px;
  height: 104px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: relative;
  .overlay {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    background-color: rgba(1, 1, 1, 0.5);
    display: none;
  }
  &:hover {
    .overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      transition: all 2s;
    }
  }
`;

export const CameraModalStyleWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .webcamWrapper {
    position: relative;
    .timerWrapper {
      position: absolute;
      top: 4px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }
  .buttonWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    .gridContainer {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
`;

export const SegmentedStyleWrapper = styled.div`
  .segmentWrapper {
    height: 36px;
    display: flex;
    background-color: rgba(1, 1, 1, 0.1);
    padding: 4px;
    border-radius: 4px;
    .item {
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
    }
    .selectedItem {
      background-color: #fff !important;
    }
  }
`;
