import React from "react";
import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const imageExtensions = ["gif", "jpg", "jpeg", "png", "svg"];

export const checkIsImage = (fileName) => {
  if (fileName) {
    let ext = null;
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex !== -1) {
      ext = fileName.slice(dotIndex + 1).toLowerCase();
      // check if image
      if (imageExtensions.includes(ext)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
};

export const base64ToBlobAndFile = (base64String, filename) => {
  // Split the base64 string to get the MIME type and data
  const arr = base64String.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Create a File object
  let originalFile = new File([u8arr], filename, { type: mime });
  // Create a blob url
  let blobUrl = URL.createObjectURL(originalFile);
  return {
    originalFile,
    blobUrl,
  };
};

export const getFileIcon = (fileName) => {
  if (fileName) {
    let ext = null;
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex !== -1) {
      ext = fileName.slice(dotIndex + 1).toLowerCase();
    }
    // pdf file
    if (ext === "pdf") {
      return (
        <FontAwesomeIcon
          icon={faFilePdf}
          style={{
            height: "36px",
            width: "36px",
            color: "#DB4437",
          }}
        />
      );
    } else if (["doc", "docx"].includes(ext)) {
      // word file
      return (
        <FontAwesomeIcon
          icon={faFileWord}
          style={{
            height: "36px",
            width: "36px",
            color: "#4285F4",
          }}
        />
      );
    } else if (ext === "xlsx") {
      // excel file
      return (
        <FontAwesomeIcon
          icon={faFileExcel}
          style={{
            height: "36px",
            width: "36px",
            color: "#0F9D58",
          }}
        />
      );
    } else if (
      ["mp4", "mov", "wmv", "mkv", "3gp", "ogg", "webm"].includes(ext)
    ) {
      // video file
      return (
        <FontAwesomeIcon
          icon={faVideo}
          style={{
            height: "36px",
            width: "36px",
            color: "#5573be",
          }}
        />
      );
    } else {
      // except above files
      return (
        <FontAwesomeIcon
          icon={faFile}
          style={{
            height: "36px",
            width: "36px",
            color: "#5573be",
          }}
        />
      );
    }
  }
  // By default file
  return (
    <FontAwesomeIcon
      icon={faFile}
      style={{
        height: "36px",
        width: "36px",
        color: "#5573be",
      }}
    />
  );
};
