import React from "react";
import { SegmentedStyleWrapper } from "./index.style";

const SegmentedTab = ({ options, onTabClick, selectedTab }) => {
  const handleOnClick = (item) => {
    if (onTabClick) {
      onTabClick(item);
    }
  };
  return (
    <SegmentedStyleWrapper>
      <div className="segmentWrapper">
        {options &&
          options.length > 0 &&
          options.map((item, index) => {
            return (
              <div
                key={index}
                className={`${
                  selectedTab.value === item.value
                    ? "item selectedItem"
                    : "item"
                }`}
                onClick={() => handleOnClick(item)}
              >
                {item.label}
              </div>
            );
          })}
      </div>
    </SegmentedStyleWrapper>
  );
};

export default SegmentedTab;
