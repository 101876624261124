import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCalendarDay,
  faComments,
  faList,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StatisticsCard from "../uielements/StatisticsCard";
import Api from "../../helpers/apiHandler";
import countsActions from "../../redux/counts/actions";
import { newPermissionValue } from "../../helpers/constants";

const { updateCount } = countsActions;
const api = new Api();

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const TaskMessagingCounts = ({
  onUpdateCount,
  counts,
  companyId,
  sideBarItems,
  ...props
}) => {
  const [count, setCount] = useState({});
  const [isTasks, setIsTasks] = useState(false);

  useEffect(() => {
    if (sideBarItems && sideBarItems.length > 0) {
      sideBarItems.forEach((item) => {
        if (item.moduleName === "task") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsTasks(true);
          } else {
            setIsTasks(false);
          }
        }
      });
    }
  }, [sideBarItems]);

  useEffect(() => {
    if (companyId) {
      getCount();
    }
  }, [companyId]);

  useEffect(() => {
    if (counts) {
      setCount(counts);
    }
  }, [counts]);

  const getCount = () => {
    const payload = {
      companyId: companyId,
      menuType: "TASK_AND_MESSAGING",
    };
    api
      .post("common/count", {
        isMultipart: false,
        data: payload,
      })
      .then((response) => {
        if (response.status === 1) {
          onUpdateCount({
            taskAndMessaging: {
              currentTasks: response.data.taskCounts[0].current_task,
              upcomingTasks: response.data.taskCounts[0].upcoming_task,
              unreadMessages: 0,
              unreadNotifications: 0,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <GridContainer className="width-100">
      {isTasks && (
        <StatisticsCard
          title={"Tasks"}
          count={
            (count.currentTasks || 0) + (count.upcomingTasks || 0)
          }
          icon={
            <FontAwesomeIcon
              icon={faTasks}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#9D6AB0"}
          onClick={() => props.history.push("/task")}
        />
      )}
      {isTasks && (
        <StatisticsCard
          title={"Upcoming Tasks"}
          count={count.upcomingTasks ? count.upcomingTasks : 0}
          icon={
            <FontAwesomeIcon
              icon={faList}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#16B9CE"}
          onClick={() => props.history.push("/task")}
        />
      )}
      <StatisticsCard
        title={"Unread Messages"}
        count={0}
        icon={
          <FontAwesomeIcon
            icon={faComments}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#EE5A92"}
        onClick={() => props.history.push("/messages")}
      />
      <StatisticsCard
        title={"Unread Notifications"}
        count={0}
        icon={
          <FontAwesomeIcon
            icon={faBell}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#8BBC27"}
      />
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.Auth.company.id,
  counts: state.Counts.taskAndMessaging,
  sideBarItems: state.Auth.sideBarItems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCount: (data) => dispatch(updateCount(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskMessagingCounts)
);
