import React from "react";
import { Modal, Button } from "antd";

class Delete extends React.Component {
  state = {
    visible: false,
  };

  render() {
    return (
      <div>
        <Modal
          title=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="deleteConfirmModal"
          footer={null}
          bodyStyle={{
            padding: "32px",
          }}
          width={440}
        >
          <p
            className="f-size-24px"
            style={{ fontWeight: 500, color: "#262626" }}
          >
            Delete Confirmation
          </p>
          <p className="pa-top-6px f-size-16px">
            {this.props.message
              ? this.props.message
              : "Are you sure you want to delete this record?"}
          </p>
          <div className="display-flex gap-12px pa-top-26px">
            <Button
              key="delete"
              type="primary"
              onClick={this.handleOk}
              loading={this.props.deleteButtonLoading}
            >
              Delete
            </Button>
            <Button key="cancel" onClick={this.handleCancel}>
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    );
  }

  componentWillReceiveProps(props) {
    this.setState({
      visible: props.visible,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.props.confirmDelete();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.props.closeDeletePopup();
  };
}

export default Delete;
