import React from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFileUpload } from "@fortawesome/free-solid-svg-icons";

import Button from "../button";

const UploadModal = ({
  visible,
  onCancel,
  setIsOpenCameraModal,
  handleUpload,
  handleCaptureFromCameraClick,
  ...props
}) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      className="generalModal"
      bodyStyle={{
        padding: "24px",
      }}
      width={340}
      onCancel={onCancel}
      centered
      closable={false}
    >
      <div className="display-flex flex-column align-center">
        {
          props.isHideCamera?"":<>
        <Button
          className="borderedButton plr-12px"
          size="large"
          onClick={handleCaptureFromCameraClick}
        >
          <div className="display-flex align-center gap-8px">
            <FontAwesomeIcon
              icon={faCamera}
              style={{
                height: "20px",
                width: "20px",
                color: "#5573be",
              }}
            />
            Capture From Camera
          </div>
        </Button>
        <span className="ptb-8px">or</span>
          </>
        }
        
        <Button
          className="borderedButton plr-12px"
          size="large"
          onClick={handleUpload}
        >
          <div className="display-flex align-center gap-8px">
            <FontAwesomeIcon
              icon={faFileUpload}
              style={{
                height: "20px",
                width: "20px",
                color: "#5573be",
              }}
            />
            Browse Files
          </div>
        </Button>
      </div>
    </Modal>
  );
};

export default UploadModal;
