import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import PaymentScreen from "./containers/Payments/PaymentScreen";
import { withRouter } from "react-router";

const RestrictedRoute = withRouter(({ component: Component, isLoggedIn, location, history, ...rest }) => {
  if (!isLoggedIn) {
    // Save the current route where the user is trying to go
    localStorage.setItem('lastVisitedRoute', location.pathname);
    return (
      <Redirect to={{ pathname: "/signin" }} />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  )
});
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path={"/500"}
          component={asyncComponent(() => import("./containers/Page/500"))}
        />
        <Route
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/signin"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/signup"}
          component={asyncComponent(() => import("./containers/Page/signup"))}
        />
        <Route
          exact
          path={"/label"}
          component={asyncComponent(() =>
            import("./components/nutritionlabel/Label1")
          )}
        />
        <Route
          exact
          path={"/verificationCode/:code"}
          component={asyncComponent(() =>
            import("./containers/Page/VerificationCode")
          )}
        />
        <Route
          exact
          path={"/forgotpassword"}
          component={asyncComponent(() =>
            import("./containers/Page/forgotPassword")
          )}
        />
        <Route
          exact
          path={"/forgotPassword/verificationCode/:code"}
          component={asyncComponent(() =>
            import("./containers/Page/ForgotVerificationCode")
          )}
        />
        <Route
          exact
          path={"/resetpassword"}
          component={asyncComponent(() =>
            import("./containers/Page/resetPassword")
          )}
        />
        <Route exact path={"/paymentinfo"} component={PaymentScreen} />
        <Route
          exact
          path={"/free-trial"}
          component={asyncComponent(() => import("./containers/trail/Trail"))}
        />
        <Route
          exact
          path={"/plan-sheet"}
          component={asyncComponent(() => import("./containers/trail/PlanSheet"))}
        />
        <Route
          exact
          path={"/facts"}
          component={asyncComponent(() => import("./components/nutritionpage/NutritionLabelFacts"))}
        />
        <RestrictedRoute path="" component={App} isLoggedIn={isLoggedIn} history={history} />
      </Switch>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export default (connect(mapStateToProps)(PublicRoutes));
