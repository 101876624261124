import { Icon } from "antd";
import React from "react";

const CameraButton = ({
  type, // type = "photo" || "video" || "video-capture"
  onClick,
  ...props
}) => {
  let color = type === "photo" || type === "video" ? "#486bb3" : "#DB4437";
  return (
    <div
      style={{
        border: `2px solid ${color}`,
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        padding: type === "video-capture" ? "12px" : "4px",
        cursor: "pointer",
      }}
      onClick={onClick}
      {...props}
    >
      <div
        className="width-100 height-100 display-flex justify-center align-center f-size-18px"
        style={{
          borderRadius: type === "video-capture" ? "20%" : "50%",
          backgroundColor: color,
        }}
      >
        {type !== "video-capture" && (
          <Icon
            type={type === "photo" ? "camera" : "video-camera"}
            style={{ color: "#fff" }}
          />
        )}
      </div>
    </div>
  );
};

export default CameraButton;
