import styled from "styled-components";
import { palette } from "styled-theme";

const LayoutContentStyle = styled.div`
  width: 100%;
  padding: 28px;
  background-color: #ffffff;
  border: 1px solid ${palette("border", 0)};
  height: 100%;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  min-height: 65vh;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export default LayoutContentStyle;
