import React, { Component, Fragment, useEffect } from "react";

import { Route, withRouter, Switch } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";
import { connect } from "react-redux";
import Page404 from "../Page/404";
import Loader from "../../components/utility/loader";

const routes = [
  {
    path: "dashboard",
    component: asyncComponent(() => import("../dashboard")),
  },
  {
    path: "company",
    component: asyncComponent(() => import("../../containers/Company/index")),
    exact: false,
  },
  {
    path: "employees",
    component: asyncComponent(() => import("../../containers/Employee/index")),
    exact: false,
  },
  {
    path: "teams",
    component: asyncComponent(() => import("../../containers/Teams/index")),
    exact: false,
  },
  {
    path: "roles",
    component: asyncComponent(() => import("../../containers/Roles/index")),
    exact: false,
  },
  {
    path: "manufacturer",
    component: asyncComponent(() =>
      import("../../containers/Manufacturer/index")
    ),
    exact: false,
  },
  {
    path: "supplier",
    component: asyncComponent(() => import("../../containers/Supplier/index")),
    exact: false,
  },
  {
    path: "certification",
    component: asyncComponent(() =>
      import("../../containers/GlobalCertification/index")
    ),
    exact: false,
  },
  // {
  //   path: "units",
  //   component: asyncComponent(() => import("../../containers/Units/index")),
  //   exact: false,
  // },
  {
    path: "customers",
    component: asyncComponent(() => import("../Customers")),
    exact: false,
  },
  {
    path: "customer_orders",
    component: asyncComponent(() => import("../CustomerOrders")),
    exact: false,
  },
  {
    path: "ingredients",
    component: asyncComponent(() =>
      import("../../containers/Ingredients/index")
    ),
    exact: false,
  },
  {
    path: "inventory",
    component: asyncComponent(() => import("../../containers/Inventory/index")),
    exact: false,
  },
  {
    path: "purchase-orders",
    component: asyncComponent(() => import("../../containers/Orders/index")),
    exact: false,
  },
  {
    path: "orders",
    component: asyncComponent(() => import("../../containers/Orders/index")),
    exact: false,
  },
  {
    path: "inspection",
    component: asyncComponent(() =>
      import("../../containers/Inspection/index")
    ),
    exact: false,
  },
  {
    path: "recipe",
    component: asyncComponent(() => import("../../containers/Recipes/index")),
    exact: false,
  },
  {
    path: "messages",
    component: asyncComponent(() => import("../../containers/Messages/index")),
    exact: false,
  },
  {
    path: "reports",
    component: asyncComponent(() => import("../../containers/Reports")),
    exact: false,
  },
  {
    path: "visitors",
    component: asyncComponent(() => import("../../containers/Visitor/index")),
    exact: false,
  },
  {
    path: "plan_detail",
    component: asyncComponent(() =>
      import("../../containers/PlanDetails/index")
    ),
    exact: false,
  },
  {
    path: "my-settings",
    component: asyncComponent(() => import("../MySettingsModule")),
    exact: false,
  },
  {
    path: "global-settings",
    component: asyncComponent(() => import("../SettingsModule")),
    exact: false,
  },
  {
    path: "companyDetails",
    component: asyncComponent(() =>
      import("../../containers/CompanyDetails/index")
    ),
    exact: false,
  },
  {
    path: "task",
    component: asyncComponent(() => import("../../containers/Task/index")),
    exact: false,
  },
  {
    path: "production",
    component: asyncComponent(() =>
      import("../../containers/Production/index")
    ),
    exact: false,
  },
  {
    path: "editProfile",
    component: asyncComponent(() =>
      import("../../components/user/editProfile")
    ),
  },
  {
    path: "reports/ingredients/view",
    exact: true,
    component: asyncComponent(() => 
      import("../../containers/Reports/ViewIngredientReport")
    ),
  },
];

const superAdminRoutes = [
  {
    path: "companies",
    exact: false,
    component: asyncComponent(() => import("../Companies/index")),
  },
  {
    path: "membership",
    component: asyncComponent(() => import("../GlobalMembership/index")),
  },
  {
    path: "units",
    component: asyncComponent(() => import("../../containers/Units/index")),
    exact: false,
  },
  {
    path: "roles",
    component: asyncComponent(() => import("../../containers/Roles/index")),
    exact: false,
  },
];

const staticRoutes = [
  {
    path: "setup",
    component: asyncComponent(() => import("../../containers/Setup")),
  },
  {
    path: "editProfile",
    component: asyncComponent(() =>
      import("../../components/user/editProfile")
    ),
  },
  {
    path: "employeeForm",
    component: asyncComponent(() =>
      import("../../containers/Employee/employeeForm")
    ),
  },
];

class AppRouter extends Component {
  state = {
    newRoutes: []
  }
  componentDidMount() {
    const { url, style, permissions, userType } = this.props;
    const newRoute =
      userType === "admin"
        ? routes
        : userType === "superadmin"
          ? superAdminRoutes
          : routes.filter((route) => {
            const result = permissions.find(
              (module) =>
                module.moduleName.toLowerCase() === route.path.toLowerCase()
            );
            return result ? result.permissionId !== 5 : false;
          });

    const alwaysIncludeRoutes = ["editProfile", "companyDetails"];
    alwaysIncludeRoutes.forEach((path) => {
      if (!newRoute.find((route) => route.path === path)) {
        const routeToAdd = routes.find((route) => route.path === path);
        if (routeToAdd) {
          newRoute.push(routeToAdd);
        }
      }
    });
    this.setState({
      newRoutes: newRoute
    })

  }
  componentWillReceiveProps(nextProps) {
    const { permissions, userType, location } = nextProps;
  
    const newRoute =
      userType === "admin"
        ? routes
        : userType === "superadmin"
        ? superAdminRoutes
        : routes.filter((route) => {
            let result = permissions.find(
              (module) => module.moduleName === route.path
            );
            return result ? result.permissionId !== 5 : false;
          });
  
    // Check if userType is employee before checking the route
    if (userType === "employee") {
      const index = newRoute.findIndex((item) =>
        location.pathname.startsWith(`/${item.path}`)
      );

      if (index === -1) {
        this.props.history.push("/dashboard");
      }
    }

    // console.log(newRoute, "newRoute");

    this.setState({
      newRoutes: newRoute,
    });
  }


  render() {
    const { url, style, permissions, userType, location } = this.props;
    const { newRoutes } = this.state;

    // const newRoutes =
    //   userType === "admin"
    //     ? routes
    //     : userType === "superadmin"
    //       ? superAdminRoutes
    //       : routes.filter((route) => {
    //         let result = permissions.find(
    //           (module) => module.moduleName === route.path
    //         );
    //         return result ? result.permissionId !== 5 : false;
    //       });




    return userType ? (
      <div style={style}>
        <Switch>
          {newRoutes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
          {staticRoutes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
          <Route
            exact={false}
            key={"messages"}
            path={`/messages`}
            component={asyncComponent(() =>
              import("../../containers/Messages/index")
            )}
          />
          <Route path="*" component={Page404} />
        </Switch>
      </div>
    ) : (
      <div className="loader-container">
        <div className="blur-background"></div>
        <div className="loader">
          <Loader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.Auth.sideBarItems,
  userType: state.Auth.type,
});

export default withRouter(connect(mapStateToProps)(AppRouter));
