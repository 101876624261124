import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import Webcam from "react-webcam";
import { useStopwatch } from "react-timer-hook";

import Button from "../button";
import CameraButton from "./CameraButton";
import { CameraModalStyleWrapper } from "./index.style";
import { base64ToBlobAndFile } from "./utils";
import SegmentedTab from "./SegmentedTab";
import Timer from "./Timer";

const CameraModal = ({
  visible,
  onCancel,
  handleCameraUpload,
  allowVideoCapture,
  ...props
}) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState({
    label: "Photo",
    value: "photo",
  });
  const [cameraMode, setCameraMode] = useState("photo"); // "photo" or "video"
  const [facingMode, setFacingMode] = useState("user");
  const [isCaptured, setIsCaptured] = useState(false);
  const [isVideoCapturing, setIsVideoCapturing] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const { seconds, minutes, hours, start, reset } = useStopwatch({
    autoStart: false,
  });

  const startTimer = () => {
    start();
  };

  const stopTimer = () => {
    reset();
  };

  const toggleFacingMode = () => {
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  };

  // start video capture
  const handleStartVideoCapture = useCallback(() => {
    setIsVideoCapturing(true);
    startTimer();
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    let recordedChunks = [];
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        recordedChunks.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = () => {
      stopTimer();
      const blobFile = new Blob(recordedChunks, { type: "video/webm" });
      let originalFile = new File(
        [blobFile],
        `video-${new Date().getTime()}.webm`,
        { type: blobFile.type }
      );
      let blobUrl = URL.createObjectURL(originalFile);
      recordedChunks = [];
      setVideoSrc({ originalFile, blobUrl });
      setIsVideoCapturing(false);
      setIsCaptured(true);
    };
    mediaRecorderRef.current.start();
  }, [webcamRef, setIsVideoCapturing, mediaRecorderRef]);

  // stop video capture
  const handleStopVideoCapture = () => {
    mediaRecorderRef.current.stop();
  };

  // handle photo capture
  const handlePhotoCapture = useCallback(() => {
    const base64Image = webcamRef.current.getScreenshot();
    let item = base64ToBlobAndFile(
      base64Image,
      `image-${new Date().getTime()}.jpeg`
    );
    setImageSrc(item);
    setIsCaptured(true);
  }, [webcamRef]);

  const handleUpload = () => {
    if (cameraMode === "video" && videoSrc) {
      handleCameraUpload(videoSrc);
    } else if (cameraMode === "photo" && imageSrc) {
      handleCameraUpload(imageSrc);
    } else {
      message.error("Something went wrong.");
    }
  };

  const handleRemove = () => {
    setVideoSrc(null);
    setImageSrc(null);
    setIsCaptured(false);
  };

  useEffect(() => {
    setImageSrc(null);
    setIsCaptured(false);
  }, []);

  return (
    <Modal
      visible={visible}
      footer={null}
      className="generalModal"
      bodyStyle={{
        padding: "16px",
        height: "520px",
      }}
      width={600}
      onCancel={onCancel}
      centered
      closable={false}
    >
      <CameraModalStyleWrapper>
        {!isCaptured ? (
          <div className="webcamWrapper">
            <Webcam
              style={{ width: "100%" }}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: facingMode,
              }}
              ref={webcamRef}
            />
            {isVideoCapturing && (
              <div className="timerWrapper">
                <Timer hours={hours} minutes={minutes} seconds={seconds} />
              </div>
            )}
          </div>
        ) : (
          <div>
            {cameraMode === "photo" ? (
              <img
                src={imageSrc.blobUrl}
                alt="captured-image"
                style={{ width: "100%", height: "100%", display: "block" }}
              />
            ) : (
              <video
                controls
                style={{ width: "100%", height: "100%", display: "block" }}
              >
                <source src={videoSrc.blobUrl} type="video/webm" />
              </video>
            )}
          </div>
        )}
        <div className="buttonWrapper">
          {!isCaptured ? (
            <>
              {allowVideoCapture ? (
                <div className="width-100 gridContainer">
                  <div className="display-flex align-center">
                    <div
                      className="w-130px"
                      style={{
                        visibility: isVideoCapturing ? "hidden" : "visible",
                      }}
                    >
                      <SegmentedTab
                        options={[
                          { label: "Photo", value: "photo" },
                          { label: "Video", value: "video" },
                        ]}
                        selectedTab={selectedTab}
                        onTabClick={(item) => {
                          setSelectedTab(item);
                          setCameraMode(item.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="display-flex justify-center align-center">
                    {isVideoCapturing ? (
                      <CameraButton
                        type="video-capture"
                        onClick={handleStopVideoCapture}
                      />
                    ) : (
                      <>
                        {cameraMode === "photo" ? (
                          <CameraButton
                            type="photo"
                            onClick={handlePhotoCapture}
                          />
                        ) : (
                          <CameraButton
                            type="video"
                            onClick={handleStartVideoCapture}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="display-flex justify-end align-center">
                    <div
                      style={{
                        visibility: isVideoCapturing ? "hidden" : "visible",
                      }}
                    >
                      <Button
                        size="small"
                        icon="retweet"
                        style={{ fontSize: "16px" }}
                        className="borderedButton"
                        onClick={toggleFacingMode}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="display-flex justify-center align-center gap-12px">
                  <CameraButton type="photo" onClick={handlePhotoCapture} />
                  <Button
                    size="small"
                    icon="retweet"
                    style={{ fontSize: "16px" }}
                    className="borderedButton"
                    onClick={toggleFacingMode}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="display-flex justify-center align-center gap-12px">
              <Button type="primary" onClick={handleUpload}>
                Upload
              </Button>
              <Button onClick={handleRemove}>Remove</Button>
            </div>
          )}
        </div>
      </CameraModalStyleWrapper>
    </Modal>
  );
};

export default CameraModal;
