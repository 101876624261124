const options = [
  {
    moduleName: "dashboard",
    moduleId: 1,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Dashboard",
    subModules: [],
  },
  {
    moduleName: "company",
    moduleId: 2,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Locations",
    subModules: [],
  },
  {
    moduleName: "employees",
    moduleId: 3,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Employees",
    subModules: [],
  },
  {
    moduleName: "manufacturer",
    moduleId: 4,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Manufacturers",
    subModules: [],
  },
  {
    moduleName: "supplier",
    moduleId: 5,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Suppliers",
    subModules: [],
  },
  {
    moduleName: "ingredients",
    moduleId: 6,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Ingredients",
    subModules: [],
  },
  // {
  //   moduleName: "units",
  //   moduleId: 7,
  //   permissionId: 1,
  //   moduleStatus: "Active",
  //   moduleDescription: "Weights and Measures",
  // },
  {
    moduleName: "customers",
    moduleId: 8,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Customers",
    subModules: [],
  },
  {
    moduleName: "customer_orders",
    moduleId: 9,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Customer Orders",
    subModules: [],
  },
  {
    moduleName: "purchase-orders",
    moduleId: 10,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Purchase Orders",
    subModules: [],
  },
  {
    moduleName: "reports",
    moduleId: 11,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Reports",
    subModules: [],
  },
  {
    moduleName: "recipe",
    moduleId: 12,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Recipes",
    subModules: [],
  },
  {
    moduleName: "inventory",
    moduleId: 13,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Inventory",
    subModules: [],
  },
  {
    moduleName: "inspection",
    moduleId: 14,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Factory Inspections",
    subModules: [],
  },
  {
    moduleName: "visitors",
    moduleId: 15,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Visitor Log",
    subModules: [],
  },
  {
    moduleName: "settings",
    moduleId: 16,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Settings",
    subModules: [],
  },
  {
    moduleName: "teams",
    moduleId: 17,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Teams",
    subModules: [],
  },
  {
    moduleName: "roles",
    moduleId: 18,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Roles",
    subModules: [],
  },
  // {
  //   moduleName: "Subscription",
  //   moduleId: 19,
  //   permissionId: 1,
  //   moduleStatus: "Active",
  //   moduleDescription: "Subscriptions",
  // },
];

export const superadminDashboard = [
  {
    moduleName: "companies",
    moduleId: 1,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Companies",
  },
  {
    moduleName: "membership",
    moduleId: 2,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Membership Settings",
  },
  {
    moduleName: "units",
    moduleId: 3,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Weights and Measures",
  },
  {
    moduleName: "roles",
    moduleId: 4,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Roles",
  },
  {
    moduleName: "feedbacks",
    moduleId: 5,
    permissionId: 1,
    moduleStatus: "Active",
    moduleDescription: "Feedbacks",
  },
];

export default options;
