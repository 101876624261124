import { Icon, message } from "antd";
import React, { useRef, useState } from "react";

import { StyleWrapper } from "./index.style";
import FileCard from "./FileCard";
import UploadModal from "./UploadModal";
import CameraModal from "./CameraModal";

/*  fileList must be array of objects and objects must be in following format:
    {
      url: String or null,
      blobUrl: String or null,
      originalFile: File object or null
    }
    Notes: 
    >> when "url" field is string then "blobUrl" and "originalFile" field must be null.
    >> when "blobUrl" and "originalFile" field will be present then "url" field must be null.
*/

const FileUpload = React.forwardRef((props, fileInputRef) => {
  const localFileInputRef = useRef();
  const fileInput = fileInputRef || localFileInputRef;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCameraModal, setIsOpenCameraModal] = useState(false);

  const handleUpload = () => {
    if (fileInput.current) {
      fileInput.current.click();
    } else {
      console.error("File input ref is not available");
    }
  };

  const handleRemoveFile = (index) => {
    let tempFileList = [...props.fileList];
    tempFileList.splice(index, 1);
    props.onChange(tempFileList);
  };

  const handleFileInputChange = (event) => {
    let files = event.target.files;
    if (files && files.length > 0) {
      let tempFileList = [];
      for (let i = 0; i < files.length; i++) {
        let customFileObj = {
          url: null,
          blobUrl: URL.createObjectURL(files[i]),
          originalFile: files[i],
        };
        tempFileList.push(customFileObj);
      }
      props.onChange([...props.fileList, ...tempFileList]);
      setIsOpenModal(false);
    }
  };

  const handleCameraUpload = (item) => {
    setIsOpenCameraModal(false);
    setIsOpenModal(false);
    props.onChange([
      ...props.fileList,
      {
        url: null,
        blobUrl: item.blobUrl,
        originalFile: item.originalFile,
      },
    ]);
  };

  const handleCaptureFromCameraClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (stream) {
        setIsOpenCameraModal(true);
      }
    } catch (error) {
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        message.error(
          "Camera permission denied. Please allow camera access to use this feature."
        );
      } else if (
        error.name === "NotFoundError" ||
        error.name === "DevicesNotFoundError"
      ) {
        message.error(
          "No camera device found. Please connect a camera to use this feature."
        );
      }
    }
  };

  return (
    <StyleWrapper>
      <input
        type="file"
        ref={fileInput}
        accept={props.accept}
        hidden
        multiple={props.multiple}
        onChange={handleFileInputChange}
      />
      <div className="display-flex align-center gap-12px flex-wrap">
        {/* Uploaded Files */}
        {props.fileList &&
          props.fileList.length > 0 &&
          props.fileList.map((item, index) => {
            return (
              <FileCard
                key={index}
                showPreviewIcon={props.showPreviewIcon}
                showRemoveIcon={props.showRemoveIcon}
                customFileObj={item}
                handleRemoveFile={() => handleRemoveFile(index)}
              />
            );
          })}
        {/* Upload Button */}
        {!props.hideUploadButton && (
          <div
            className="uploadButton"
            ref={props.uploadRef || null}
            onClick={() => {
              if(props.isDirectOpen){
                handleUpload()
              }else{
                setIsOpenModal(true);
              }
            }}
          >
            <Icon type={props.isLoading ? "loading" : "plus"} />
            <div className="btnText">
              {props.isLoading ? "Uploading" : props.uploadText || "Upload"}
            </div>
          </div>
        )}
      </div>
      {isOpenModal && (
        <UploadModal
          visible={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
          setIsOpenCameraModal={setIsOpenCameraModal}
          handleUpload={handleUpload}
          handleCaptureFromCameraClick={handleCaptureFromCameraClick}
          isHideCamera={props.isHideCamera}
        />
      )}
      {isOpenCameraModal && (
        <CameraModal
          visible={isOpenCameraModal}
          onCancel={() => setIsOpenCameraModal(false)}
          handleCameraUpload={handleCameraUpload}
          allowVideoCapture={props.allowVideoCapture}
        />
      )}
    </StyleWrapper>
  );
});

export default FileUpload;
