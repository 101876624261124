export const ingredientUnits = [
  "Bag",
  "Barrel",
  "Bottle",
  "Box",
  "Bucket",
  "Can",
  "Container",
  "Crate",
  "Cup",
  "Dozen",
  "Fluid ounce",
  "Grams",
  "Gallon",
  "Jar",
  "Jug",
  "Keg",
  "KiloGram",
  "Liter",
  "Ounce",
  "Pound",
  "Tank",
  "Tablespoon",
  "Teaspoon",
  "Ton",
  "Tub",
  "Pouch",
  "Set",
  "Sack",
];
export const pullStrategy = ["FIFO", "Hand Pick", "FEFO", "LIFO"];

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const weekDays = [
  { key: "Monday", value: 2 },
  { key: "Tuesday", value: 3 },
  { key: "Wednesday", value: 4 },
  { key: "Thursday", value: 5 },
  { key: "Friday", value: 6 },
  { key: "Saturday", value: 7 },
  { key: "Sunday", value: 1 },
];

export const ingredientType = {
  default: "0",
  parts: "Part",
  percentage: "Percentage",
  type: "Type",
  measurement: "Measurement",
};

export const recipeSourceList = [
  { key: 1, name: "employee" },
  { key: 2, name: "other" },
];

export const planTypeSuccessMessage = {
  freePlanMessage: "Your free trial has been started successfully.",
  standardPlanMessage: "Your standard plan has been started successfully.",
  proPlanMessage: "Your pro plan has been started successfully.",
};

export const planText = {
  freePlan: 1,
  standardPlanMonthly: 2,
  standardPlanYearly: 4,
  proPlanMonthly: 3,
  proPlanYearly: 5,
};

export const basicPlan = {
  freePlan: 1,
  standardPlan: 2,
  proPlan: 3,
};

export const planName = {
  freePlan: "Free Blueberry Pie",
  standardPlan: "Hot Blueberry Pie",
  proPlan: "Hot Blueberry Pie Pro",
};

export const permission = {
  allAccess: 1, // edit => add edit view delete (All)
  view: 2,
  edit: 3,
  delete: 4,
  noAccess: 5,
};

export const newPermission = [
  { key: 1, value: "All Access" },
  { key: 2, value: "No Access" },
  { key: 3, value: "View Only" },
  { key: 4, value: "Custom Access" },
];

export const certificateByTime = [
  { key: "Expired", value: "Expired" },
  { key: "AboutToExpired", value: "About To Expired" },
  { key: "Active", value: "Active" },
];

export const certificateType = [
  { key: "all", value: "All" },
  { key: "companyProductionUnit", value: "Locations" },
  { key: "manufacturer", value: "Manufacturer" },
  { key: "ingredient", value: "Ingredient" },
];

export const newPermissionValue = {
  allAccess: 1,
  noAccess: 5,
  viewOnly: 2,
  customAccess: 3,
};

export const moduleIds = [
  //weightandMeasure:
  14,
  //plan_detail
  22,
];

export const permissionLevel = {
  mainMenu: "mainMenu",
  module: "module",
  options: "options",
  subModule: "subModule",
  fields: "fields",
};

export const permissionMessage = {
  noAccess: "You have no access to open the page.",
};

export const refType = {
  productionLocation: "companyProductionUnit",
  manufacturer: "manufacturer",
};

export const unitType = {
  weight: 1,
  volume: 2,
};

export const employeeType = {
  employee: 1,
  employedBy: 2,
  independentContractor: 3,
};

export const loginUserType = {
  admin: "admin",
  employee: "employee",
  superadmin: "superadmin",
};

export const modulesNotIncluded = [
  "Dashboard",
  // "Weights and Measures",
  "Reports",
  "Plan Details",
];

export const inspectionTabType = {
  past: "past",
  upcoming: "upcoming",
  directory: "directory",
};

//id of the gram from fbt/api/unit/unit-list api response
export const gramUnit = {
  GRAMUNIT: 72,
};

export const ALLOCATED_FOR = {
  AVAILABLE: "Available",
  QUARANTINED: "Quarantined",
  DAMAGED: "Damaged",
};

export const productionRunModalType = {
  allocate: "Allocate",
  deallocate: "Deallocate",
  remaining: "Remaining",
};

export const TOOL_PAGE_FIELD = {
  USE: ["useMaterialQty", "usedMaterialFor"],
  ALLOCATE: ["change_allocation", "projectName"],
  CHANGE_LOCATION: ["locQty", "location"],
  CHANGE_OWNER: ["change_owner_quantity", "owner"],
  ADJUST: ["adjust_quantity", "type"],
  REMOVE_FROM_QUARANTINE: ["rmQurQuantity"],
  REMOVE_FROM_DAMAGE: ["removeDamagedQuantity"],
  DISPOSE: ["disposeQuantity"],
};

export const CUSTOMER_TYPE = {
  Retailer: "Retail",
  Wholesaler: "Wholesale",
};


export const SAFETY_TYPE=["SDS","MSDS"]