import {
  Breadcrumb,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { Component, Fragment } from "react";

import Api from "./../../helpers/apiHandler";
import Button from "../../components/uielements/button";
import Delete from "../../components/modals/delete";
import { InputSearch } from "../../components/uielements/input";
import LayoutContent from "../../components/utility/layoutContent";
import appActions from "../../redux/app/actions";
import authAction from "../../redux/auth/actions";
import { connect } from "react-redux";
import { newPermissionValue, SAFETY_TYPE } from "../../helpers/constants";
import { TableStyleWrapper } from "../../components/utility/table.style";
import FileUpload from "../../components/uielements/FileUpload";
import ConfirmModal from "../../components/modals/confirmModal";
import { exportToS3, generateRandomString, getFileExtension } from "../../helpers/utility";
import awsPaths, { basePath } from "../../helpers/awsPaths";

const api = new Api();

const FormItem = Form.Item;
const Option = Select.Option;
let sortBy = {
  direction: "DESC",
  property: "date_created"
};
let filterData = null;
let searchText = "";

const s3ImageFlag = "safetyFileThumb";
const s3FileFlag = "safetyFile";

export class SafetyDataSheet extends Component {
  state = {
    deleteModalVisible: false,
    recordToDelete: 0,
    safetyDataSheetList: [],
    uploadInDataSheetModal: false,
    addFormType: undefined,
    addFormDocumentList:[],
    confirmModalVisible:false,
    deleteLoading:false,
    manufactureList:[],
    isManufactureLoading:false,
    isIngredientLoading:false,
    ingredientList:[],
    iFilterManufactureId:0,
    iFilterIngredientId:0,
    iUploadDocId:0,
    ingredientId:0,
    manufacturerId:0,
    fileUploadLoader:false,
    recordToDelete:0,
    deleteDataSheetId:0,
    pageData: {
      pageSize: 10,
      current: 1,
      total: 0
    },
  };


  getManufactureList(){
      this.setState({
          isManufactureLoading:true
      })
      api.get("manufacturer/manufacturer-list",{})
      .then((response) => {
          if (response.status === 1) {
              let manufacturerData = response.data.manufacturerData;

              this.setState({
                  manufactureList: manufacturerData,
                  isManufactureLoading:false
              });
          } else {
              console.log("!200");
          }
      })
      .catch((error) => {
          return error;
      });
  }

  getIngredientList(){
      this.setState({
          isIngredientLoading:true,
          ingredientList:[]
      })

      const iFilterManufactureId=this.state.iFilterManufactureId?this.state.iFilterManufactureId:0;

      api.post(`ingredient/ingredient-list?manufacturerId=${iFilterManufactureId}`,{ isMultipart: false, data: { } })
      .then((response) => {
          if (response.status === 1) {
              let ingredientData = response.data.ingredientData;

              this.setState({
                  ingredientList: ingredientData,
                  isIngredientLoading:false
              });
          } else {
              console.log("!200");
          }
      })
      .catch((error) => {
          return error;
      });
  }

  componentDidMount() {
    this.getManufactureList();
    this.getIngredientList();
    this.getSafetyDataSheetData();
  }

  componentDidUpdate(prevProps, prevState) {
      if (this.state.iFilterManufactureId !== prevState.iFilterManufactureId) {
          this.getIngredientList();
      }
      if(this.state.iFilterManufactureId !== prevState.iFilterManufactureId || this.state.iFilterIngredientId !== prevState.iFilterIngredientId){
        this.getSafetyDataSheetData();
      }

      if (this.state.pageData.current !== prevState.pageData.current) {
        this.getSafetyDataSheetData();
      }
  }


  async getSafetyDataSheetData() {
    const parameters = {
      page: { limit: this.state.pageData.pageSize, pageId: this.state.pageData.current - 1 },
      sortBy: sortBy,
      query: searchText,
      companyId: this.props.auth.company.id,
      filters:{
        ingredientId: this.state.iFilterIngredientId,
        manufacturerId: this.state.iFilterManufactureId
      },
    };

    this.setState({
        loading: true,
    });

    await api
      .post("common/safety/datasheet/list", {
        isMultipart: false,
        data: parameters,
      })
      .then((response) => {
        this.setState({
            loading: false,
        });

        if (response.status === 1) {
          let safetyDataSheetList = response.data.list;

          this.setState({
            pageData:{
              total:response.data.totalRecords,
              pageSize: this.state.pageData.pageSize,
              current: this.state.pageData.current,
            }
          })

          this.setState({
              safetyDataSheetList: safetyDataSheetList,
          });
        } else {
          console.log("!200");
        }
      })
      .catch((error) => {
        return error;
      });
  }

  openDeletePopup(id, data) {
    this.setState({
      deleteModalVisible: true,
      recordToDelete: data.id,
    });
  }

  closeDeletePopup() {
    this.setState({
      deleteModalVisible: false,
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      pageData:pagination
    })
  };

  onSearchTable(value, event) {
    searchText = value;
    this.getSafetyDataSheetData();
  }

  handleUploadAddCancel = () => {
      this.setState({
          uploadInDataSheetModal: false
      });
  }

  handleFileUpload=(fileData)=>{
      this.setState({
          addFormDocumentList:fileData
      });
  }

  confirmSuccess=async()=>{
    this.setState({
        deleteLoading:true
    })
    await api.delete("common/safety/datasheet/delete-safety-datasheet/"+this.state.deleteDataSheetId, {})
      .then((response) => {
          this.setState({
              confirmModalVisible: false,
              deleteDataSheetId:0,
              deleteLoading:false
          })
          if (response.status === 1) {
              message.success(response.message);
              this.getSafetyDataSheetData();
          } else {
              console.log("!200");
          }
      })
      .catch((error) => {
          return error;
      });
  }

  confirmCancel=()=>{
    this.setState({
      confirmModalVisible:false,
      deleteDataSheetId:0
    })
  }
  
  

  render() {
    const { getFieldDecorator, validateFieldsAndScroll, resetFields } = this.props.form;

    const columns = [
      {
        title: "Manufacturer",
        dataIndex: "manufacture",
        key: "manufacture",
      },
      {
        title: "Chemical / Product",
        dataIndex: "product",
        key: "product",
      },
      {
        title: "Uploaded By",
        dataIndex: "uploadedByName",
        key: "uploadedByName",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (text, record) => (
          <Button size="small" onClick={() => {
              window.open(`${basePath}/${awsPaths[s3FileFlag]}/${record.filePath}`);
          }}>{text}</Button>
        )
      },
      {
        title: "Action",
        key: "action",
        width: 80,
        render: (text, record) => (
          <div className="display-flex gap-6px">
             <Tooltip placement="top" title={"Edit"}>
                <Button
                  icon="edit"
                  className="editButton"
                  size="small"
                  onClick={()=>{
                    this.setState({
                      uploadInDataSheetModal:true,
                      iUploadDocId:record.id,
                      addFormType:record.type,
                      ingredientId:record.ingredientId,
                      manufacturerId:record.manufacturerId
                    })
                  }}
                />
             </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <Button
                icon="delete"
                className="deleteButton"
                size="small"
                onClick={()=>{
                  this.setState({
                    confirmModalVisible:true,
                    deleteDataSheetId:record.id
                  })
                }}
              />
            </Tooltip>
          </div>
        ),
        fixed: "right",
      },
    ];

    const handleSubmit=(e)=>{
        e.preventDefault();

        validateFieldsAndScroll(async(err, values) => {
            if (!err) {
              const firstImage=this.state.addFormDocumentList.length?this.state.addFormDocumentList[0]:[];
              
              let customFileName = this.state.addFormDocumentList.length?generateRandomString(5) + "-" + new Date().getTime() + "." + getFileExtension(firstImage.originalFile.name):"";

              if(this.state.addFormDocumentList.length){
                  let renamedFile = new File(
                      [firstImage.originalFile],
                      customFileName,
                      { type: firstImage.originalFile.type }
                  );

                  await exportToS3(renamedFile, s3FileFlag);
              }

              const parameters = {
                  filePath: customFileName,
                  id:this.state.iUploadDocId,
                  companyId: this.props.auth.company.id,
                  type:values.type,
                  uploadedBy: this.props.auth.id,
                  manufacturerId: this.state.manufacturerId,
                  ingredientId: this.state.ingredientId
              };

              this.setState({
                  fileUploadLoader:true
              })

              api.post("common/safety/datasheet/add", {
                  isMultipart: false,
                  data: parameters,
              })
              .then((response) => {
                  this.setState({
                      fileUploadLoader:false
                  })
                  if (response.status === 1) {
                      message.success(response.message);
                      this.getSafetyDataSheetData();
                      resetFields();
                  } else {
                      console.log("!200");
                  }
                  this.setState({
                      uploadInDataSheetModal: false,
                      iUploadDocId:0,
                      addFormDocumentList:[],
                      manufacturerId:0,
                      ingredientId:0
                  });
              })
              .catch((error) => {
                  return error;
              });
            }
        });
    }

    return (
      <div style={{ border: "none", marginTop: "10px" }}>
        <div
          className="display-flex justify-space-between align-center"
          style={{ marginBottom: "10px" }}
        >
          <div className="display-flex align-center gap-12px">
            <Select
                allowClear
                showSearch
                style={{ width: 300 }}
                placeholder="Select Manufacturer"
                optionFilterProp="children"
                onChange={(value)=>{
                    this.setState({
                        iFilterManufactureId:value
                    })
                }}
                loading={this.state.isManufactureLoading}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            > 
                {
                  this.state.manufactureList.map((curItem,index)=>{
                    return <Option key={index} value={curItem.id}>{curItem.name}</Option>
                  })
                }
            </Select>

            <Select
              allowClear
              showSearch
              style={{ width: 300 }}
              placeholder="Select Chemical / Product"
              optionFilterProp="children"
              onChange={(value)=>{
                  this.setState({
                      iFilterIngredientId:value
                  })
              }}
              loading={this.state.isIngredientLoading}
              filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >   
              {
                  this.state.ingredientList.map((curItem,index)=>{
                      return <Option key={index} value={curItem.id}>{curItem.name}</Option>
                  })
              }
          </Select>
          </div>
        </div>

        <br />
        <TableStyleWrapper>
          <Table
            dataSource={this.state.safetyDataSheetList}
            columns={columns}
            scroll={{
              x: true,
            }}
            className="isoSortingTable"
            pagination={this.state.pageData}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </TableStyleWrapper>


        <Modal
            title="Edit Safety"
            visible={this.state.uploadInDataSheetModal}
            footer={null}
            onCancel={this.handleUploadAddCancel}
            width={"50%"}
        >
            <Form onSubmit={handleSubmit}>
                <Row >
                    <Col lg={12}>
                        <FormItem label="Type">
                            {getFieldDecorator("type", {
                                initialValue: this.state.addFormType,
                                rules: [
                                    { required: true, message: "Please select type" },
                                ],
                            })(
                                <Select
                                    className="width-100"
                                    placeholder="Select type"
                                >
                                    {
                                        SAFETY_TYPE.map((curItem,index)=>{
                                            return <Option key={index} value={curItem}>
                                                        {curItem}
                                                    </Option>
                                        })
                                    }
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <FileUpload
                            fileList={this.state.addFormDocumentList}
                            onChange={(fileList) => {
                                this.handleFileUpload(fileList);
                            }}
                            hideUploadButton={this.state.addFormDocumentList.length}
                            multiple={false}
                            allowVideoCapture={true}
                            showPreviewIcon={this.state.addFormDocumentList.length}
                            showRemoveIcon={this.state.addFormDocumentList.length}
                            isDirectOpen={true}
                            accept="application/pdf,text/plain,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                    </Col>
                </Row>

                <div className="display-flex gap-12px pa-top-20px">
                    <Button type="primary" htmlType="submit" loading={this.state.fileUploadLoader}>Submit</Button>
                    <Button onClick={() => { resetFields(); this.handleUploadAddCancel(); }} >
                        Cancel
                    </Button>
                </div>
            </Form>
        </Modal>


        <Delete
          visible={this.state.confirmModalVisible}
          closeDeletePopup={() => this.confirmCancel()}
          confirmDelete={this.confirmSuccess}
          deleteButtonLoading={this.state.deleteLoading}
          message="Are you sure you want to delete safety data?"
        />
      </div>
    );
  }
}

const WrappedCompany = Form.create()(
    connect((state) => ({ auth: state.Auth }))(SafetyDataSheet)
);

export default WrappedCompany;
